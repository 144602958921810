import { ErrorMessage } from "@components/ErrorMessage";
import { Form, Align } from "@components/Form";
import { QuestionAnswerLink } from "@components/QuestionAnswerLink";
import { SocialLoginButtons } from "@components/SocialLoginButtons";
import { TextInput, TextInputType } from "@components/TextInput";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";

import { IdentityProvider } from "@every.org/common/src/codecs/auth";
import { ClientRouteName } from "@every.org/common/src/helpers/clientRoutes";

import { getWebAuth } from "src/context/AuthContext/WebAuth";
import {
  emailAuth0Login,
  EmailAuth0LoginStatus,
} from "src/context/AuthContext/actions";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { colorCssVars } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import {
  spacing,
  horizontalStackCss,
  verticalStackCss,
} from "src/theme/spacing";
import { LoginMethod, trackLoginAttempt } from "src/utility/analytics";
import { getAndDecodeSearchParam } from "src/utility/helpers";

const StyledForm = styled(Form)`
  margin: ${spacing.xl} 0;
`;

const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: ${spacing.oldM};
  }
`;
const EmailInput = styled(TextInput)`
  margin-bottom: ${spacing.oldM};
`;

const socialLoginButtonCss = (showFacebook: boolean) => css`
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: showFacebook
      ? css`
          max-width: 350px;
        `
      : css`
          width: 100%;
        `,
  })}
`;

export const signUpTextStyling = css`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #eaeded;
  line-height: 0.02em;
  margin: 10px 0 10px;
  span {
    background: #fff;
    padding: 0 10px;
  }
`;

export function ContinueWithSocial({
  condensed,
  showFacebook,
  isSignup = false,
  skipWelcomeModal,
  redirectUrl,
}: {
  condensed?: boolean;
  showFacebook: boolean;
  isSignup?: boolean;
  skipWelcomeModal?: boolean;
  redirectUrl?: string;
}) {
  useEffect(() => {
    getWebAuth(); // trigger it to be loaded before user hits login
  }, []);

  return (
    <div
      css={css`
        max-width: 600px;
        ${showFacebook
          ? cssForMediaSize({
              max: MediaSize.MEDIUM_SMALL,
              css: verticalStackCss.m,
            })
          : verticalStackCss.l}
        ${showFacebook &&
        cssForMediaSize({
          min: MediaSize.MEDIUM,
          css: horizontalStackCss.m,
        })}
          align-items: center;
      `}
    >
      <SocialLoginButtons
        wrapperCss={[
          cssForMediaSize({
            max: MediaSize.MEDIUM_SMALL,
            css: verticalStackCss.m,
          }),
          cssForMediaSize({
            min: MediaSize.MEDIUM,
            css: horizontalStackCss.m,
          }),
          { width: "100%" },
        ]}
        buttonCss={socialLoginButtonCss(showFacebook)}
        condensedSignUpFlow={condensed}
        longText
        showFacebook={showFacebook}
        skipWelcomeModal={skipWelcomeModal}
        redirectUrl={redirectUrl}
      />
      <p
        css={[
          css`
            color: var(${colorCssVars.text.secondary});
          `,
          isSignup && signUpTextStyling,
        ]}
      >
        <span>{isSignup ? "or sign up with email" : "or"}</span>
      </p>
    </div>
  );
}

export const REDIRECT_URL_QUERY_PARAM = "redirectUrl";

export function LoginForm() {
  useEffect(() => {
    getWebAuth(); // trigger it to be loaded before user hits login
  }, []);
  const { search } = useEdoRouter();
  const searchParams = new URLSearchParams(search);

  const [email, setEmail] = useState(
    getAndDecodeSearchParam(searchParams, "email") || ""
  );
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string>();

  const redirectUrl = getAndDecodeSearchParam(
    searchParams,
    REDIRECT_URL_QUERY_PARAM
  );

  const logIn = async () => {
    if (!password) {
      setError("Please enter password");
    } else {
      try {
        trackLoginAttempt(
          LoginMethod.EMAIL,
          IdentityProvider.USERNAME_PASSWORD
        );
        const result = await emailAuth0Login({
          email,
          password,
          redirectUrl,
        });
        if (result.status === EmailAuth0LoginStatus.ERROR) {
          setError(result.errorMessage);
        }
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        }
      }
    }
  };

  const formMessage = getAndDecodeSearchParam(searchParams, "message");

  return (
    <StyledForm
      onSubmit={logIn}
      submitButtonContent="Log in with email"
      data-tname="logInForm"
      alignActions={Align.LEFT}
      subHeadingText={formMessage}
    >
      <LoginFormContainer>
        <ContinueWithSocial showFacebook />
        <EmailInput
          id="email"
          data-tname="Login-EmailInput"
          autoComplete="username"
          type={TextInputType.EMAIL}
          name="email"
          labelText="Email"
          value={email}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
          }}
          collapseDescriptionSpace
        />
        <TextInput
          id="password"
          data-tname="Login-PasswordInput"
          autoComplete="current-password"
          type={TextInputType.PASSWORD}
          labelText="Password"
          name="password"
          value={password}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
          }}
          collapseDescriptionSpace
        />

        <ErrorMessage text={error} />
        <QuestionAnswerLink
          question="Forgot password?"
          answer="Reset"
          data-tname="resetPasswordPrompt"
          routeName={ClientRouteName.RESET_PASSWORD}
        />
        <QuestionAnswerLink
          question="New to Every.org?"
          answer="Sign up now"
          data-tname="signupPrompt"
          routeName={ClientRouteName.CHOOSE_ACCOUNT}
          routeQuery={redirectUrl ? { redirectUrl } : {}}
        />
      </LoginFormContainer>
    </StyledForm>
  );
}
